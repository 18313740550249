<template>
  <div class="container">
    <div class="header">
        <router-link class="logo" to="/">后台管理系统</router-link>
        <div class="exit" @click="logoutFunc">退出</div>
    </div>
    <div class="main">
        <div class="wrap">
            <div class="title">
                <div class="txt">会员列表</div>
                <div class="btn_group">
                    <el-button type="primary" size="small" icon="el-icon-plus" @click="addMember">新增</el-button>
                    <el-upload
                        class="member_import"
                        accept=".xls,.xlsx,csv"
                        action=""
                        :show-file-list="false"
                        :http-request="memberImportFunc">
                        <el-button type="primary" size="small" plain icon="el-icon-upload2">导入</el-button>
                    </el-upload>
                    <el-button type="primary" size="small" plain icon="el-icon-download" @click="memberExportFunc">导出</el-button>
                    <el-button type="danger" size="small" icon="el-icon-delete" @click="deleteManyMember">删除</el-button>
                </div>
            </div>
            <div class="search">
                <div class="query">
                    <el-form :inline="true" ref="form" :model="form">
                        <el-form-item label="名称：">
                            <el-input v-model="form.name" placeholder="输入会员名称"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号：">
                            <el-input v-model="form.tel" placeholder="输入会员手机号"></el-input>
                        </el-form-item>
                        <el-form-item label="状态：">
                            <el-select v-model="form.status" placeholder="状态">
                                <el-option label="全部" :value="0"></el-option>
                                <el-option label="使用中" :value="1"></el-option>
                                <el-option label="已过期" :value="2"></el-option>
                                <el-option label="已冻结" :value="3"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="btn" @click="getMemberListFunc">
                    <el-button type="primary" size="small" icon="el-icon-search"></el-button>
                </div>
            </div>
            <div class="list">
                <div class="table">
                    <el-table
                        ref="multipleTable"
                        :data="tableData"
                        tooltip-effect="dark"
                        border
                        @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55" align="center"></el-table-column>
                        <el-table-column label="会员编号" prop="userCode"></el-table-column>
                        <el-table-column prop="userName" label="会员名称"></el-table-column>
                        <el-table-column prop="userPhone" label="手机号" align="center"></el-table-column>
                        <el-table-column prop="validityDate" label="有效期" align="center"></el-table-column>
                        <el-table-column label="操作" width="180" align="center">
                            <template slot-scope="scope">
                                <div class="operate">
                                    <el-button type="text" size="mini" @click="editItemFunc(scope.row)">修改</el-button>
                                    <el-button v-if="scope.row.status == 1" type="text" size="mini" @click="dongjieFunc(scope.row.id)">冻结</el-button>
                                    <el-button v-if="scope.row.status == 3" type="text" size="mini" @click="jiedongFunc(scope.row.id)">解冻</el-button>
                                    <el-button class="delete_btn" type="text" size="mini" @click="deleteItem(scope.row.id)">删除</el-button>
                                </div>
                            </template>
                        </el-table-column>
                        <template slot="empty">
                            <el-empty description="暂时还没有数据哦~"></el-empty>
                        </template>
                    </el-table>
                </div>
                <div class="page" v-if="pages > 0">
                    <el-pagination
                        align='right'
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange" 
                        :current-page="pageNum" 
                        :page-sizes="[10,20,30]" 
                        :page-size="pageSize" 
                        layout="total, sizes, prev, pager, next, jumper" 
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
    <!-- 新增会员 -->
    <el-dialog title="新增会员" :visible.sync="addMemberFlag" width="600px">
        <memberInfor v-if="addMemberFlag" @closePop="closePopFunc"></memberInfor>
    </el-dialog>
    <!-- 编辑会员 -->
    <el-dialog title="修改会员" :visible.sync="editMemberFlag" width="600px">
        <memberInfor v-if="editMemberFlag" :editItem="editItem" @closePop="closePopFunc"></memberInfor>
    </el-dialog>
  </div>
</template>
<script>
import memberInfor from "@/components/memberInfor.vue";
import {getMemberList, dongjie, jiedong, memberDelete, memberExport, memberImport, logout, login} from "@/api/index";
export default {
    name: 'index',
    components: {
        memberInfor
    },
    data() {
        return {
            form: {
                name: '',
                tel: '',
                status: 0
            },
            pages: 0,
            total: 0,
            pageNum: 1,
            pageSize: 10,
            tableData: [],
            addMemberFlag: false,
            editMemberFlag: false,
            editItem: {},
            selectedItems: []
        }
    },
    created(){
        this.getMemberListFunc();
    },
    methods: {
        // 获取会员列表
        getMemberListFunc(){
            const params = {
                userName: this.form.name,
                userPhone: this.form.tel,
                status: this.form.status,
                pageNum: this.pageNum,
                pageSize: this.pageSize
            };
            getMemberList({},{params}).then(res=>{
                this.tableData = res.data;
                this.pages = res.pages;
                this.total = res.total;
                this.tableData.map(i=>{
                    let arr = i.validityDate.toString().split('');
                    arr.splice(4, 0, '-');
                    i.validityDate = arr.join('');
                });
            })
        },
        // 切换每页显示条数
        handleSizeChange(e){
            this.pageSize = e;
            this.getMemberListFunc();
        },
        // 切换页码
        handleCurrentChange(e){
            this.pageNum = e;
            this.getMemberListFunc();
        },
        // 新增会员
        addMember(){
           this.addMemberFlag = true; 
        },
        // 修改会员
        editItemFunc(item){
            this.editItem = item;
            this.editMemberFlag = true; 
        },
        // 关闭弹框 type == 0 关闭新增弹框，type == 1 关闭修改弹框
        closePopFunc(type){
            if(type == 0){
                this.addMemberFlag = false;
            }else{
                this.editMemberFlag = false;
            }
            this.getMemberListFunc();
        },
        // 会员冻结
        dongjieFunc(id){
            dongjie({},{params: {id}}).then(res=>{
                this.getMemberListFunc();
            });
        },
        // 会员解冻
        jiedongFunc(id){
            jiedong({},{params: {id}}).then(res=>{
                this.getMemberListFunc();
            });
        },
        // 会员删除
        memberDeleteFunc(ids){
            memberDelete({},{params: {ids}}).then(res=>{
                this.getMemberListFunc();
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            });
        },
        // 单个会员删除
        deleteItem(id){
            this.$confirm('此操作将永久删除会员, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
               this.memberDeleteFunc(id);
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消删除'
                });
            });
        },
        // 多个会员删除
        deleteManyMember(){
            if(this.selectedItems.length == 0){
                this.$message.warning('请选择需要删除的数据！');
            }else{
                let idArr = [];
                this.selectedItems.forEach((i)=>{
                    idArr.push(i.id);
                })
                let ids = idArr.join(',');
                this.$confirm('此操作将永久删除会员, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.memberDeleteFunc(ids);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消删除'
                    });
                });
            }
        },
        // 表格选中
        handleSelectionChange(e){
            this.selectedItems = e;
        },
        // 会员导出全部
        memberExportFunc(){
            memberExport().then(res=>{
                this.$message({
                    type: 'success',
                    message: '导出成功!'
                });
            });
        },
        // 会员导入
        memberImportFunc(e){
            let formData = new FormData();
            formData.append('file', e.file);
            memberImport(formData).then(res=>{
                this.$message({
                    type: 'success',
                    message: '导入成功!'
                });
                this.getMemberListFunc();
            });
        },
        // 退出登录
        logoutFunc(){
            logout().then(res=>{
                localStorage.removeItem('token');
                this.$router.push('/login')
            })
        }
    }
}
</script>
<style lang="less" scoped>
.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        padding: 0 30px;
        background-color: var(--theme-color);
        .logo{
            font-size: 24px;
            color: #fff;
            text-decoration: none;
        }
        .exit{
            padding: 5px 0 5px 5px;
            color: #fff;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .main{
        flex: 1;
        overflow-y: auto;
        .wrap{
            width: 1200px;
            margin: 0 auto;
            .title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px 0;
                border-bottom: 1px solid #ddd;
                .txt{
                    font-size: 18px;
                }
                .btn_group{
                    display: flex;
                    .member_import{
                        margin: 0 10px;
                    }
                }
            }
            .search{
                display: flex;
                align-items: center;
                padding: 20px 0;
                .query{
                    /deep/ .el-form-item{
                        margin-bottom: 0;
                        .el-form-item__label{
                            line-height: 33px;
                            padding: 0;
                        }
                        .el-form-item__content{
                            line-height: 33px;
                            .el-input__inner{
                                height: 33px;
                                line-height: 33px;
                            }
                            .el-input__icon{
                                line-height: 33px;
                            }
                        }
                    }
                }
            }
            .list{
                .table{
                    /deep/ .el-table{
                        th.el-table__cell{
                            background-color: #f5f5f5;
                            
                        }
                        .operate{
                            .el-button{
                                padding: 0;
                                &.delete_btn{
                                    color: #F56C6C;
                                }
                            }
                        }
                        
                    }
                }
                .page{
                    .el-pagination{
                        padding: 20px 0;
                    }
                }
            }
        }
    }
}
</style>