import request from "@/utils/request"
//登录
export function login(data, option){
    return request.post("/login", data, option)
}
// 登出
export function logout(data, option){
    return request.post("/logout", data, option)
}
// 获取会员列表
export function getMemberList(data, option){
    return request.post("/admin/member/list", data, option)
}
// 添加会员
export function addMember(data, option){
    return request.post("/admin/member/save", data, option)
}
// 修改会员
export function editMember(data, option){
    return request.post("/admin/member/update", data, option)
}
// 校验会员编号
export function verifyCode(data, option){
    return request.post("/admin/member/verifyCode", data, option)
}
// 校验会员手机号
export function verifyPhone(data, option){
    return request.post("/admin/member/verifyPhone", data, option)
}
// 会员冻结
export function dongjie(data, option){
    return request.post("/admin/member/dongjie", data, option)
}
// 会员解冻
export function jiedong(data, option){
    return request.post("/admin/member/jiedong", data, option)
}
// 会员删除
export function memberDelete(data, option){
    return request.post("/admin/member/delete", data, option)
}
// 会员导出全部
export function memberExport(data, option){
    return request.export("/admin/member/export",  option)
}
// 会员导入
export function memberImport(data, option){
    return request.post("/admin/member/import", data, option)
}