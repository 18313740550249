<template>
  <div class="member_infor">
    <div class="pop_body">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="会员编号" prop="userCode">
                <el-input v-model="form.userCode" placeholder="请输入会员编号" maxlength="30"></el-input>
            </el-form-item>
            <el-form-item label="会员名称" prop="userName">
                <el-input v-model="form.userName" placeholder="请输入会员名称" maxlength="20"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="userPhone">
                <el-input v-model="form.userPhone" placeholder="请输入会员联系电话"></el-input>
            </el-form-item>
            <el-form-item label="有效期" prop="validityDate">
                <el-date-picker v-model="form.validityDate" type="month" placeholder="请选择有效期" value-format="yyyyMM"></el-date-picker>
            </el-form-item>
        </el-form>
    </div>
    <div class="pop_foot">
        <el-button type="primary" @click="submit">确定</el-button>
        <el-button plain @click="cancle">取消</el-button>
    </div>
  </div>
</template>
<script>
import {addMember, editMember, verifyCode, verifyPhone} from '@/api/index';
export default {
    name: 'memberInfor',
    props: ['editItem'],
    data() {
        return {
            form: {
                userCode: '',
                userName: '',
                userPhone: '',
                validityDate: ''
            },
            rules: {
                userCode: [
                    { required: true, message: '请输入会员编号', trigger: 'blur' }
                ],
                userName: [
                    { required: true, message: '请输入会员名称', trigger: 'blur' }
                ],
                userPhone: [
                    { required: true, message: '请输入会员联系电话', trigger: 'blur' },
                    { 
                        validator: function(rule, value, callback) {
                            let reg1 = /^(?:(?:\d{3}-)?\d{8}|^(?:\d{4}-)?\d{7,8})(?:-\d+)?$/,
                                reg2 = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/;


                            if (reg1.test(value) || reg2.test(value)) {
                                callback();
                            } else {
                                callback(new Error("联系电话格式错误"));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                validityDate: [
                    { required: true, message: '请选择有效期' }
                ]
            }
        }
    },
    created(){
        if(this.editItem){
            let {id,userCode,userName,userPhone,validityDate} = this.editItem;
            this.form = {id,userCode,userName,userPhone,validityDate};
            let arr = this.form.validityDate.toString().split('');
            arr.splice(4, 1);
            this.form.validityDate = arr.join('');
        }
    },
    methods: {
        // 添加会员
        addMemberFunc(){
            let params = {...this.form};
            addMember({},{params}).then(res=>{
                this.cancle();
            })
        },
        // 修改会员
        editMemberFunc(){
            let params = {...this.form};
            editMember({},{params}).then(res=>{
                this.cancle();
            })
        },
        // 确定
        submit(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if(this.editItem){
                        const params = {
                            id: this.form.id,
                            userCode: this.form.userCode
                        };
                        verifyCode({},{params}).then(res=>{
                            const params2 = {
                                id: this.form.id,
                                userPhone: this.form.userPhone
                            };
                            verifyPhone({},{params: params2}).then(res=>{
                                this.editMemberFunc();
                            })
                        })
                    }else{
                        const params = {
                            userCode: this.form.userCode
                        };
                        verifyCode({},{params}).then(res=>{
                            const params2 = {
                                userPhone: this.form.userPhone
                            };
                            verifyPhone({},{params: params2}).then(res=>{
                                this.addMemberFunc();
                            })
                        })
                    }
                } else {
                    return false;
                }
            });
        },
        // 取消
        cancle(){
            let type = 0;
            if(this.editItem){
                type = 1;
            }
            this.$emit('closePop',type)
        }
    }
}
</script>
<style lang="less" scoped>
.member_infor{
    .pop_body{
        padding: 0 20px 0 10px;
        .el-date-editor.el-input{
            width: 100%;
        }
    }
    .pop_foot{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0 0;
    }
}
</style>