import axios from 'axios';
import { Message} from 'element-ui';
import router from '@/router';
const http = {}
//axios.create()是添加了自定义配置的新的axios
let baseURL = '/api';
var instance = axios.create({
    timeout: 5000,
    baseURL
})
var fileinstance = axios.create({
    timeout: 5000,
    baseURL,
    responseType: "blob"
})
// 添加请求拦截器
instance.interceptors.request.use(
    function (config) {
        // 请求头添加token
        if (localStorage.getItem('token')) {
            config.headers.Authorization = localStorage.getItem('token')
        }
        return config
    },
    function (error) {
        //romise.reject(reason)方法返回一个带有拒绝原因（reason参数）的Promise对象。
        return Promise.reject(error)
    }
)
// 响应拦截器即异常处理
instance.interceptors.response.use(
    response => {
        if (response.headers["content-type"] == "application/json") {
            return response.data
        }else {
            return response
        }
    },
    err => {
        if (err && err.response) {
            switch (err.response.status) {
                case 400:
                    err.message = '请求出错'
                    break
                case 401:
                    Message.warning({
                        message: '授权失败，请重新登录'
                    })
                    router.push('/login')
                    return
                case 403:
                    err.message = '拒绝访问'
                    break
                case 404:
                    err.message = '请求错误,未找到该资源'
                    break
                case 500:
                    err.message = '服务器端出错'
                    break
            }
        } else {
            err.message = '连接服务器失败'
        }
        Message.error({
            message: err.message
        })
        return Promise.reject(err.response)
    }
)
// 添加请求拦截器
fileinstance.interceptors.request.use(
    function (config) {
        // 请求头添加token
        if (localStorage.getItem('token')) {
            config.headers.Authorization = localStorage.getItem('token')
        }
        return config
    },
    function (error) {
        //romise.reject(reason)方法返回一个带有拒绝原因（reason参数）的Promise对象。
        return Promise.reject(error)
    }
)
// 响应拦截器即异常处理
fileinstance.interceptors.response.use(
    response => {
        if (response.headers["content-type"] == "application/json") {
            return response.data
        }else {
            return response
        }
    },
    err => {
        if (err && err.response) {
            switch (err.response.status) {
                case 400:
                    err.message = '请求出错'
                    break
                case 401:
                    Message.warning({
                        message: '授权失败，请重新登录'
                    })
                    router.push('/login')
                    return
                case 403:
                    err.message = '拒绝访问'
                    break
                case 404:
                    err.message = '请求错误,未找到该资源'
                    break
                case 500:
                    err.message = '服务器端出错'
                    break
            }
        } else {
            err.message = '连接服务器失败'
        }
        Message.error({
            message: err.message
        })
        return Promise.reject(err.response)
    }
)
http.get = function (url, options) {
    return new Promise((resolve, reject) => {
        instance
            .get(url, options)
            .then(response => {
                if (response.code === 0) {
                    resolve(response.data)
                } else {
                    Message.error({
                        message: response.msg
                    })
                    reject(response.code)
                }
            })
            .catch(e => {
                console.log(e)
            })
    })
}
http.post = function (url, data, params) {
    return new Promise((resolve, reject) => {
        instance
            .post(url, data, params)
            .then(response => {
                if (response.code === 0) {
                    resolve(response.data)
                } else {
                    Message.error({
                        message: response.msg
                    })
                    reject(response.code)
                }
            })
            .catch(e => {
                console.log(e)
            })
    })
}

http.export = function(url,params){
    return new Promise((resolve, reject) => {
        fileinstance
            .get(url, params)
            .then(res => {
                let headers = res.headers
                let filename = "会员列表.xls"
                const link = document.createElement('a');
                let blob = new Blob([res.data]);
                link.style.display = 'none';
                //设置连接
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                document.body.appendChild(link);
                //模拟点击事件
                link.click();
            })
            .catch(e => {
                console.log(e)
            })
    })
}
export default http
